import React from "react";
import bannerData from "../data/data.json";
import "./description.css";
import { useState, useRef, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import Spinner from "react-bootstrap/Spinner";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-input-2/lib/style.css";
const recaptchaRef = React.createRef();
function validatePhoneNumber(contactPhone) {
  const numericPhoneNumber = contactPhone.replace(/\D/g, "");
  if (!/^\d{5,15}$/.test(numericPhoneNumber)) {
    return false;
  }
  return true;
}

function Description() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [enterYourName, setEnterYourName] = useState("");
  const [enterYourCompanyName, setEnterYourCompanyName] = useState("");
  const [enterEmail, setEnterEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [shipFrom, setShipFrom] = useState("");
  const [shipTo, setShipTo] = useState("");
  // const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState(true);
  const [message, setMessage] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [loading, setLoading] = useState(false);
  const multiselectRef = useRef();
  const key = "6LecAaEpAAAAAM-pliHwoexDQ3-f728DegZ-iO8h";
  // Options for the Multiselect component
  const options = [
    { cat: "Group 1", key: "RORO доставка" },
    { cat: "Group 1", key: "Буксировка транспортных" },
    { cat: "Group 1", key: "доставка одним грузополучателем" },
  ];
  const handleSelectionChange = (selectedItems) => {
    setSelectedValues(selectedItems);
  };
  const resetSelectField = () => {
    multiselectRef.current.resetSelectedValues();
  };
  async function submitForm() {
    // window.grecaptcha.reset();

    setLoading(true);
    if (enterYourName.length == 0) {
      setLoading(false);
      alert("Введите ваше имя ");
      return;
    }

    // if (enterYourCompanyName.length == 0) {
    //   alert("Введите название вашей компании");
    //   setLoading(false);
    //   return;
    // }

    if (!validatePhoneNumber(contactPhone)) {
      alert("введите действующий номер телефона с кодом страны");
      setLoading(false);
      return;
    }
    if (!emailPattern.test(enterEmail)) {
      alert("введите действующую  электронную почту");
      setLoading(false);
      return;
    }

    if (shipFrom.length == 0) {
      alert("введите место  прибытия");
      setLoading(false);
      return;
    }
    if (shipTo.length == 0) {
      alert("введите место отбытия");
      setLoading(false);
      return;
    }
    if (!captchaInput) {
      alert("Пожалуйста, заполните CAPTCHA");
      setLoading(false);
      return;
    }
    const formData = {
      enterYourName: enterYourName,
      enterYourCompanyName: enterYourCompanyName,
      contactPhone: contactPhone,
      enterEmail: enterEmail,
      shipFrom: shipFrom,
      shipTo: shipTo,
      message: message,
      selectedValues: selectedValues,
    };

    try {
      const response = await fetch(
        "https://autoshipping-ru.overseasshippinginc.com/send-enquiryEmail",
        {
          method: "POST",

          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setEnterYourName("");
        setEnterYourCompanyName("");
        setContactPhone("");
        setShipFrom("");
        setEnterEmail("");
        // setCaptcha(generateCaptcha());
        setCaptchaInput(false);
        recaptchaRef.current.reset();
        // window.grecaptcha.reset();
        setShipTo("");
        setMessage("");
        setLoading(false);
        resetSelectField();
        toast.success("Form submitted successfully!");
      } else {
        console.error("Failed to submit form");
        toast.error("Form submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }

    return true;
  }
  // function generateCaptcha(length = 6) {
  //   const characters =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let captcha = "";
  //   for (let i = 0; i < length; i++) {
  //     captcha += characters.charAt(
  //       Math.floor(Math.random() * characters.length)
  //     );
  //   }
  //   return captcha;
  // }
  function onChange() {
    setCaptchaInput(true);
  }
  return (
    <>
      <div className="container container-style">
        <h2 className="description-title">
          Готовы к отправке? Мастера автоперевозок на Ближний Восток, в Африку
          или Европу-прямо здесь и сейчас!{" "}
          <b className="description-subtitle"></b>
        </h2>
        <div className="row">
          <div className=" col-lg-6">
            <div className="description-image"></div>
            <p className="description-paragraph">
              {bannerData.services.description[0].descriptionparagraph}
            </p>
            <p className="description-paragraph">
              {bannerData.services.description[0].descriptionparagraph1}
            </p>
          </div>
          <div className=" col-lg-6">
            <div className="container formborder-style">
              <div className="card-header">
                <h3 className="formheader-style">Забронировать перевозку</h3>
              </div>
              <div className="flex flex-col w-1/2 gap-4 pb-4">
                <div className="flex justify-between">
                  <div className="row">
                    <div className="col-sm-12"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type={"text"}
                    className="w-full p-2 font-medium border-style  rounded-md border-slate-300 placeholder:opacity-60 "
                    placeholder={"Введите ваше имя*"}
                    value={enterYourName}
                    onChange={(e) => setEnterYourName(e.target.value)}
                  />
                </div>
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type={"text"}
                    className="w-full p-2 font-medium border-style rounded-md border-slate-300 placeholder:opacity-60 modelTop-2"
                    placeholder={"Введите название  компании"}
                    value={enterYourCompanyName}
                    onChange={(e) => setEnterYourCompanyName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col w-1/2 gap-2 pb-4">
                <div className="flex justify-between"></div>
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  {/* <FontAwesomeIcon icon={faFlag} /> */}
                  <PhoneInput
                    value={contactPhone}
                    inputClass="border-style"
                    placeholder={"введите номер телефона на английском языке*"}
                    country={"us"}
                    onChange={(value) => setContactPhone(value)}
                  >
                    <input
                      regions={[
                        "america",
                        "europe",
                        "asia",
                        "oceania",
                        "africa",
                        "middle-east",
                      ]}
                    />
                  </PhoneInput>
                </div>
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type={"email"}
                    className="w-full p-2 font-medium border-style rounded-md border-slate-300 placeholder:opacity-60 modelTop-2"
                    placeholder={"Введите вашу электронную почту*"}
                    value={enterEmail}
                    onChange={(e) => setEnterEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col w-1/2 gap-2 pb-4 ">
                <div className="flex justify-between"></div>
              </div>
              <div className="flex flex-col w-1/2 gap-2 pb-4 multiselect-style">
                <Multiselect
                  options={options}
                  displayValue="key"
                  onKeyPressFn={function noRefCheck() {}}
                  onSelect={handleSelectionChange}
                  onRemove={handleSelectionChange}
                  onSearch={function noRefCheck() {}}
                  selectionLimit={4}
                  ref={multiselectRef}
                  value={selectedValues}
                  placeholder="Выберите Вашу услугу"
                />
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type={"text"}
                    className="w-full p-2 font-medium border-style rounded-md border-slate-300 placeholder:opacity-60"
                    placeholder={"Отправка из  *"}
                    value={shipFrom}
                    onChange={(e) => setShipFrom(e.target.value)}
                  />
                </div>
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type={"text"}
                    className="w-full p-2 font-medium border-style rounded-md border-slate-300 placeholder:opacity-60 modelTop"
                    placeholder={"Отправка в*"}
                    value={shipTo}
                    onChange={(e) => setShipTo(e.target.value)}
                  />
                </div>
              </div>
              <br></br>
              <div className="flex flex-col  gap-2 pb-4">
                <div className="flex justify-between">
                  <label className="font-semibold capitalize text-base"></label>
                </div>
                <input
                  type={"text"}
                  className="w-full p-5  font-medium border-style rounded-md border-slate-300 placeholder:opacity-60 textmessage-style"
                  placeholder={"Сообщение"}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="row">
                <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="recaptcha-style">
                    <ReCAPTCHA
                      sitekey={key}
                      ref={recaptchaRef}
                      onExpired={() => {
                        recaptchaRef.current.reset();
                      }}
                      onChange={onChange}
                      value={captchaInput}
                    />
                    {/* {captcha} */}
                  </div>
                </div>
                {/* <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <input
                    type="text"
                    className="w-full p-2 font-medium border-style rounded-md border-slate-300 placeholder:opacity-60"
                    placeholder="أدخل الحروف"
                    style={{ background: "white" }}
                    value={captchaInput}
                    onChange={(e) => setCaptchaInput(e.target.value)}
                  />
                </div> */}
              </div>
              <input
                type="submit"
                value="Отправить"
                className="button-jio button-pys"
                onClick={() => {
                  submitForm();
                }}
              ></input>{" "}
              {loading && (
                <Spinner
                  animation="border"
                  variant="info"
                  className="spinners-style"
                />
              )}
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="description-cta">
          {bannerData.services.description[0].descriptioncta}
        </h3>
      </div>
    </>
  );
}
export default Description;
