import React, { useState } from "react";
import logo from "../assets/overseas-logo-white2.png";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faXTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "./header.css";

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <div>
      <Navbar expand="sm" className="nav-tce">
        <Container>
          <Navbar.Brand href="#home">
            <Link to="/" className="block-7lq">
              <img src={logo} className="logo-style" alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href=""></Nav.Link>
              <Nav.Link href="#link"></Nav.Link>
              <NavDropdown
                title="Languages"
                id="basic-nav-dropdown"
                show={showDropdown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <NavDropdown.Item href="">Русский</NavDropdown.Item>
                <NavDropdown.Item href="https://autoshipping.overseasshippinginc.com">
                  العربيه
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Brand href="">
            <p className="navheaderText-style">
              Надежные эксперты по<br></br> автоперевозкам Звоните
            </p>

            <p className="navheaderText-style1"> 703-348-9916</p>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div id="fixed-social">
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=17039286787"
            className="fixed-whatspp"
          >
            {" "}
            <FontAwesomeIcon icon={faWhatsapp} className="icons-style" />{" "}
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/overseasshippinginc?mibextid=ZbWKwL"
            className="fixed-facebook"
          >
            <FontAwesomeIcon icon={faFacebook} className="icons-style" />{" "}
          </a>
        </div>
        <div>
          <a
            href="https://x.com/Shipwithoversea?t=ei6U2yGsqk2jcP639hl_mg&s=08"
            className="fixed-xtwitter"
          >
            {" "}
            <FontAwesomeIcon icon={faXTwitter} className="icons-style" />{" "}
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/overseasshipping?igsh=MXYwNnVkNHQ1cnNldQ=="
            className="fixed-instagram"
          >
            {" "}
            <FontAwesomeIcon icon={faInstagram} className="icons-style" />{" "}
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/overseas-shipping-71267bb7"
            className="fixed-linkedin"
          >
            {" "}
            <FontAwesomeIcon icon={faLinkedin} className="icons-style" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default Header;
