import React from "react";
import "./benefits.css";
import plane from "../assets/auto-shipping4.jpg";
import BestAutoShipping from "../assets/best-auto-shipping-rates.png";
import Efficient from "../assets/efficient-service.png";
import Experience from "../assets/experienced-staff.png";
import Trusted from "../assets/secure-trusted-auto-shipping.png";
import Track from "../assets/track-auto-shipments.png";
import Inventory from "../assets/inventory-management.png";
import Shipments from "../assets/auto-shipping-icon.png";

function Benefits() {
  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-6  leftContainer">
          <div className="">
            <div className="mt-5">
              <img className="whyImg" src={plane}></img>
              <div className="left-container-text  ">
                <div className="row">
                  <div className="col-sm-3">
                    {" "}
                    <img src={Shipments} className="roro-shipping"></img>
                  </div>
                  <div
                    className="col-sm-9 roro-shipping-content"
                    style={{ color: "black" }}
                  >
                    {" "}
                    Специалисты по автодоставке из США по всему миру
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-5 col-md-12 col-lg-12 col-xl-5  benefits-section">
          <div className="subtitle-wrap">
            <p className="subtitle">Преимущества</p>
            <div className="subtitle-border"></div>
          </div>

          <h2 className="section-rx5">
            {" "}
            Гарантированные преимущества услуг автодоставки
          </h2>
          <p className="pb-4 benefits-paragraph">
            Упростите международные автодоставки из США в порты Ближнего
            Востока, Африки и Европы. Доверьтесь нашему опыту в обеспечении
            соответствующего требованиям, надежного транспорта и четкой связи.
          </p>
          <div className="why-text pt-4">
            <div className="row">
              <div className="col-sm-2 ">
                {" "}
                <div className="icon-qg7">
                  <img src={BestAutoShipping}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-2">
                <h3 className="benefits-Heading">
                  Лучшие тарифы на автодоставку
                </h3>
              </div>
              <div className="col-sm-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Efficient}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading1">Эффективное обслуживание </h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Track}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading4">Отслеживание поставок</h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Trusted}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-2">
                <h3 className="benefits-Heading3">
                  надежная автоматическая доставка
                </h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Experience}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading2">Опытный персоналf</h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Inventory}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading5">управление запасами</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="leftContainer w-1/2">
        <img className="whyImg" src={plane}></img>
        <div className="left-container-text flex">
          <div className="icon-container">
            <img src={Shipments}></img>
          </div>
          <div className="text-container-icon">
            Moving your products across world
          </div>
        </div>
      </div>
      <div className="rightContainer w-1/2">
        <div className="subtitle-wrap">
          <p className="subtitle">Benefits</p>
          <div className="subtitle-border"></div>
        </div>

        <h2 className="section-rx5">Frequently Asked Questions</h2>
        <p className="pb-4">
          Rest assured, we handle long-distance car transportation efficiently
          and cost-effectively, ensuring your vehicle reaches the nearest port
          from your location. Proficient in Middle East shipping regulations and
          Languages.
        </p>
        <div className="why-text flex pt-4">
          <div className="why-text-left w-1/2">
            <div className="list-o6k style-Fhg5H pb-4" id="style-Fhg5H">
              <div className="icon-qg7">
                <img src={BestAutoShipping}></img>
              </div>
              <h3 className="about-ywj">Best AutoShipping Rates</h3>
            </div>
            <div className="list-o6k style-Fhg5H  pb-4" id="style-Fhg5H">
              <div className="icon-qg7">
                <img src={Efficient}></img>
              </div>
              <h3 className="about-ywj">Efficient Service</h3>
            </div>
            <div className="list-o6k style-Fhg5H  pb-4" id="style-Fhg5H">
              <div className="icon-qg7">
                <img src={Experience}></img>
              </div>
              <h3 className="about-ywj">Experienced Staff</h3>
            </div>
          </div>
          <div className="why-text-right w-1/2">
            <div className="list-o6k style-Fhg5H  pb-4" id="style-Fhg5H">
              <div className="icon-qg7">
                <img src={Trusted}></img>
              </div>
              <h3 className="about-ywj">Secure Trusted Auto Shipping</h3>
            </div>
            <div className="list-o6k style-Fhg5H  pb-4" id="style-Fhg5H">
              <div className="icon-qg7">
                <img src={Track}></img>
              </div>
              <h3 className="about-ywj">Track AutoShipments</h3>
            </div>
            <div className="list-o6k style-Fhg5H  pb-4" id="style-Fhg5H">
              <div className="icon-qg7">
                <img src={Inventory}></img>
              </div>
              <h3 className="about-ywj">Inventory Management</h3>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Benefits;
