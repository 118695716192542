import React from "react";
import "./footer.css";
import footerLogo from "../assets/overseas-logo-white2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Zoom";
import pdf from "../assets/Overseas.pdf";
function footer() {
  const handleClick = () => {
    window.open(pdf, "_blank");
  };
  const scrollBottom = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    // [`& .${tooltipClasses.arrow}`]: {
    //   color: theme.palette.common.black,
    // },
    [`& .${tooltipClasses.tooltip}`]: {
      background: "linear-gradient(to right bottom, #ffb629, #ffda56)",
    },
  }));
  return (
    <div>
      <footer class="footer-section">
        <div class="container">
          <div className="row">
            <div className="col-12 col-lg-4"></div>
          </div>
          <div class="footer-content pt-5 pb-5">
            <div class="row">
              <div class="col-xl-4 col-lg-4 mb-50">
                <div class="footer-widget">
                  <div class="footer-logo">
                    <a href="">
                      <img src={footerLogo} alt="auto-shipping-services"></img>
                    </a>
                    <p className="footer-text">
                      эксперты по авто/международным перевозкам
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 mb-50">
                <BootstrapTooltip
                  title={
                    <h6 style={{ color: "black", marginTop: "5px" }}>
                      Click Here
                    </h6>
                  }
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: "10px",
                            marginLeft: "220px",
                          },
                      },
                    },
                  }}
                >
                  <img
                    className="certificate-style"
                    onClick={handleClick}
                  ></img>
                </BootstrapTooltip>
              </div>
              <div class="col-xl-4 col-lg-4 mb-50">
                <FontAwesomeIcon icon={faPhone} className="footericon-style" />{" "}
                <p className="footerText-style1">
                  {" "}
                  Надежные эксперты по автоперевозкам Звоните
                </p>
                <br></br>
                <p className="footerText-style1">703-348-9916</p>
                <BootstrapTooltip
                  title={
                    <h6 style={{ color: "black", marginTop: "5px" }}>
                      Return To Top
                    </h6>
                  }
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  slotProps={{
                    popper: {
                      sx: {
                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: "5px",
                            marginLeft: "500px",
                          },
                      },
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    style={{
                      fontSize: "12px",
                      marginTop: "5px",
                      color: "white",
                    }}
                    className="arrow-style"
                    onClick={() => scrollBottom()}
                  />{" "}
                </BootstrapTooltip>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="copyright-area">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 ">
                <div class="copyright-text">
                  <p>
                    ©2024 Overseas Shipping Inc - All Rights Reserved
                    <a href=""></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="copyRights">
          <h5 className="copyRights-style">
            ©2024 Overseas Shipping Inc – Все права защищены
          </h5>
        </div>
        <div></div>
      </footer>
    </div>
  );
}
export default footer;
