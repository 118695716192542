import React, { useState, useEffect, useRef } from "react";
import "./testimonials.css";

function Testimonials() {
  const [slidesCount, setSlidesCount] = useState(
    window.innerWidth <= 980 ? 8 : 4
  );
  let slide = useRef(null);
  // const slidesCount = window.innerWidth <= 768 ?20:10;
  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      changeSlide(false);
      setSlidesCount(window.innerWidth <= 980 ? 8 : 4);
    };

    handleResize();
    changeSlide(false);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const maxLeft = (slidesCount - 1) * 100 * -1;

  let current = 0;

  function changeSlide(next = true) {
    if (next) {
      current = current > maxLeft ? current - 100 : maxLeft;
    } else {
      current = current < 0 ? current + 100 : 0;
    }
    console.log(current);

    if (slide.current) {
      slide.current.style.left = current + "%";
    }
  }
  return (
    <>
      <div className="section-kt1">
        <div className="row">
          <div className="col-12">
            <div className="container-rwt overflow-wxy">
              <div>
                <div className="subtitle-a6c">
                  <p className="subtitle-qto">Отзывы</p>
                  <div className="subtitle-aqf"></div>
                </div>
                <h2 className="section-bjn"></h2>
              </div>

              <div className="carousel">
                <div
                  className="slides  testimonial-slide"
                  ref={slide}
                  style={{ left: 0, transition: "left 0.5s ease" }}
                >
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">Дэвид</h3>
                            <p className="author-j5t">Тема, Гана</p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        Компания Overseas Shipping профессионально выполнила
                        перевозку моего автомобиля из шоу-рума в США в г. Тема.
                        Они также оформили все таможенные документы.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              Джим Вайс
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              Германия
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        Мой автомобиль прибыл вовремя, без повреждений и в срок.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">Али Вахдан</h3>
                            <p className="author-j5t">Алжир</p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        Отличное обслуживание. Представители были любезны,
                        информативны и вежливы. Мой автомобиль был доставлен в
                        пункт назначения своевременно, в целости и сохранности.
                        Я очень рекомендую эту компанию всем, кто хочет
                        перевезти свой автомобиль.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              Тимми Смит
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              Великобритания
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        Я получил свою машину в Лондоне. Все прошло гладко.
                        Никаких царапин или вмятин. Я позвонил в несколько
                        компаний, но эти ребята предложили самую низкую цену и
                        оказались самыми надежными. Мне пришлось связаться с
                        ними всего пару раз в процессе, чтобы узнать
                        приблизительную дату доставки.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">Надежда4жизнь</h3>
                            <p className="author-j5t">Тема, Гана</p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        Весь процесс заказа был прост от начала и до конца. Все
                        было сделано точно по расписанию, а стоимость была
                        вполне разумной, учитывая расстояние. Я определенно буду
                        рекомендовать эту компанию
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              Нэнси Уайт
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              Хьюстон, Техас
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        Мой опыт работы с компанией Overseas Shipping был только
                        положительным... Обслуживание, которое я получила, было
                        образцовым... очень индивидуальным и давало мне
                        уверенность в том, что мой автомобиль будет доставлен в
                        пункт назначения в целости и сохранности. Я рекомендую
                        вас всем своим друзьям, желающим перевезти свои
                        автомобили. Вы просто лучшие.
                      </p>
                    </div>
                  </div>

                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              Чарльз Чинка
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              Нигерия
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        Нашел эту компанию в Интернете и решил нанять их для
                        перевозки моего автомобиля, основываясь на действительно
                        хорошем обслуживании клиентов, плюс цена соответствовала
                        моему бюджету. Никаких нареканий, все было сделано
                        вовремя и в рамках бюджета.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">Аднан Лэсвод</h3>
                            {/* <p className="author-j5t">Nigeria</p> */}
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        Быстрая доставка, честное обслуживание клиентов. Очень
                        рекомендую всем, кто хочет отправить товар за границу.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carouselArrow flex justify-center">
                <div
                  className="row-va2 slide-hi8 style-KTGA2"
                  id="style-KTGA2"
                  onClick={() => changeSlide(false)}
                >
                  <div className="hid-fp1 slide-8tf"></div>
                  <img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63a690a1a014a5b94ceef00a_testimonial-left-arrow.png"
                    className="style-vnoDw"
                    id="style-vnoDw"
                  ></img>
                </div>
                <div
                  className="row-v6h slide-y5b style-iXr2r"
                  id="style-iXr2r"
                  onClick={() => changeSlide(true)}
                >
                  <div className="hid-fp1 slide-l4v"></div>
                  <img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63a68e3c0aa24416f9724b30_testimonial-right-arrow.png"
                    className="style-xOlIt"
                    id="style-xOlIt"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Testimonials;
